var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('standard-modal',{staticClass:"ui-on-boarding-widget",attrs:{"fixed-height":true,"footer-is-hidden":false,"full-screen-on-mobile":true,"header-is-hidden":true,"modal-id":_vm.modalId,"visible":_vm.isReadyToDisplay,"body-class":"p-0 d-flex min-h-unset","footer-class":"p-0 flex-column","size":"lg"},scopedSlots:_vm._u([{key:"body",fn:function(){return [(_vm.totalSteps > 1)?_c('b-tabs',{staticClass:"w-100",attrs:{"no-fade":true,"content-class":"w-100","nav-wrapper-class":"d-none"}},[(_vm.isMobile && _vm.currentStep > 1)?_c('div',{staticClass:"position-fixed back-button bg-white rounded cursor-pointer",on:{"click":_vm.prevStep}},[_c('font-awesome-component',{attrs:{"container-classes":"icon-24","icon":"fa-regular fa-arrow-left","icon-classes":"icon-16 text-neutral-n-8-dark-ink"}})],1):_vm._e(),(_vm.isMobile)?_c('div',{staticClass:"back-button-spacing"}):_vm._e(),_vm._t("default")],2):_vm._t("default")]},proxy:true},{key:"footer",fn:function(){return [(_vm.totalSteps > 1)?_c('b-progress',{staticClass:"w-100 m-0",attrs:{"max":_vm.totalSteps,"value":_vm.currentStep,"height":"0.5rem","variant":"blue-b-3-primary-blue"}}):_vm._e(),_c('div',{class:[
        'd-flex align-items-center column-gap-2 w-100 m-0 p-3 py-md-4 px-md-5',
        {'justify-content-between': _vm.isMobile}
      ]},[_c('div',{class:[
          {'w-100': _vm.isMobile},
          {'mr-auto': !_vm.isMobile} ]},[_c('button-component',{staticClass:"border-0",attrs:{"text":("" + (_vm.$t('on-boarding.skip'))),"size":"md","variant":"light-gray"},on:{"on-click":_vm.submit}})],1),(_vm.totalSteps > 1 && !_vm.isMobile)?_c('button-component',{staticClass:"border-0",attrs:{"disabled":_vm.currentStep === 1,"text":("" + (_vm.$t('on-boarding.prev'))),"size":"md","variant":"light-gray"},on:{"on-click":_vm.prevStep}}):_vm._e(),(_vm.currentStep === _vm.totalSteps)?_c('div',{class:[
          {'w-100': _vm.isMobile}
        ]},[_c('button-component',{staticClass:"border-0",attrs:{"text":("" + (_vm.$t('on-boarding.done'))),"size":"md","variant":"primary"},on:{"on-click":_vm.submit}})],1):_vm._e(),(_vm.totalSteps > 1 && _vm.currentStep < _vm.totalSteps)?_c('div',{class:[
          {'w-100': _vm.isMobile}
        ]},[_c('button-component',{staticClass:"border-0",attrs:{"text":("" + (_vm.$t('on-boarding.next'))),"size":"md","variant":"primary"},on:{"on-click":_vm.nextStep}})],1):_vm._e()],1)]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }